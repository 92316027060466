import React from "react";
import classNames from "classnames";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import { Container, Text, Button, AnimateIn } from "@atoms";

const JobList = ({ jobs, showDivider }) => {
  const lang = useLang();

  return (
    <section
      className={classNames("py-20 md:py-28", {
        "border-t-2 border-black": showDivider,
      })}
    >
      <Container>
        {!!jobs?.length && (
          <div className="flex flex-wrap gap-20">
            {jobs?.map((job, i) => {
              const { uid, heading, descriptor, link } = job;
              return (
                <AnimateIn
                  key={uid}
                  preset="fadeUpFast"
                  delay={0.2 * (i + 1)}
                  className="flex max-w-2xl flex-col justify-between gap-12 lg:flex-[0_1_calc(50%-2.5rem)]"
                >
                  <div className="flex flex-col gap-12">
                    <Text variant="h3">{heading}</Text>
                    {descriptor && <Text variant="body">{descriptor}</Text>}
                  </div>
                  <Button to={link}>{t("Learn More", lang)}</Button>
                </AnimateIn>
              );
            })}
          </div>
        )}
      </Container>
    </section>
  );
};

export default JobList;
